<template>
  <div
    :class="[
      slots.instructions ? 'mb-1' : null
    ]"
    class="fr-label flex content-center items-center">
    <slot></slot>
    <FrRequired v-if="required"></FrRequired>

    <div v-if="slots.instructions" class="ml-auto">
      <slot name="instructions"></slot>
    </div>

    <slot name="end"></slot>
  </div>
</template>
<script setup>
import { useSlots } from 'vue'
import FrRequired from '@ui/FrForm/FrRequired.vue'

defineProps({
  required: {
    type: Boolean,
    default: false
  }
})

const slots = useSlots()
</script>
